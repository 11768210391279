<div class="mb-3">
    <span class="mb-3 ml-3 text-secondary"><strong>TikTok In Feed Ads</strong></span>
</div>
<div class="pl-3">
    <div *ngIf="creative.name" class="my-3 row align-items-center ml-0 py-3" style=" border-width: 0.5px 0 0.5px 4px; border-style: solid; border-color: #e2e1e1 #e2e1e1 #e2e1e1 rgb(219, 71, 38);">
        <div class="col-lg-9 col-12 px-2 text-center d-flex align-items-center">
            <label class="my-0 font-weight-bold pl-3">{{this.creative.name}}</label>
            <label class="my-0" *ngIf="functions.checkAreDatesVisible(creative.flightStartDate, creative.flightEndDate, campaign.startDate, campaign.endDate, creative.creativeGroup, campaign.creatives, campaign.campaignType).isVisible">{{functions.checkAreDatesVisible(creative.flightStartDate, creative.flightEndDate, campaign.startDate, campaign.endDate, creative.creativeGroup, campaign.creatives, campaign.campaignType).datesLabel}}</label>
        </div>
    </div>

    <div *ngIf="!isUploadOk && !reviewPage" class="mb-3 mt-3 row ml-0 invalid-message-box d-flex align-items-center" style="min-height: 3em;">
        <span class="pl-3">Incorrect file type or incorrect file dimensions or incorrect file size.</span>
    </div>

    <div class="mb-4 mt-3 pt-3">
        <div class="row align-items-center">
            <div class="col-lg-2 col-12">
                <label><strong>App or Brand Name</strong></label>
            </div>
            <div class="col-lg-10 col-12">
                <div class="row">
                    <div class="col-12">
                        <label *ngIf="reviewPage" class="mr-0 pr-0">{{creative.detailJson.appOrBrandName}}</label>
                        <div class="input-group" *ngIf="!reviewPage">
                            <input class="form-control" type="text" [maxlength]=40 [(ngModel)]="creative.detailJson.appOrBrandName" [title]="creative.detailJson.appOrBrandName" style="overflow: hidden; text-overflow: ellipsis;" [disabled]="!functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)">
                            <label class="input-group-append d-flex align-items-center justify-content-center px-2">{{40-creative.detailJson.appOrBrandName.length}} CHARACTERS LEFT</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-2 col-12">
                <label><strong>Ad Description</strong></label>
            </div>
            <div class="col-lg-10 col-12">
                <div class="row">
                    <div class="col-12">
                        <label *ngIf="reviewPage" class="mr-0 pr-0">{{creative.detailJson.description}}</label>
                        <div class="input-group" *ngIf="!reviewPage">
                            <input class="form-control" type="text" [maxlength]=100 [(ngModel)]="creative.detailJson.description" [title]="creative.detailJson.description" style="overflow: hidden; text-overflow: ellipsis;" [disabled]="!functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)">
                            <label class="input-group-append d-flex align-items-center justify-content-center px-2">{{100-creative.detailJson.description.length}} CHARACTERS LEFT</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center mb-2">
            <div class="col-lg-2 col-12">
                <label><strong>Call to Action</strong></label>
            </div>
            <div class="col-lg-10 col-12">
                <p-dropdown class="mr-0 pr-0" [style]="{width: '100%'}" (onChange)="setCreativeCallToAction($event)" *ngIf="!reviewPage " autoWidth="false" [(ngModel)]="cta" [options]="callToActionDD" [disabled]="!functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)"></p-dropdown>
                <label *ngIf="reviewPage" class="mr-0 pr-0">{{callToActionLabel}}</label>
            </div>
        </div>
    </div>

    <div class="mb-3 d-flex flex-wrap">
        <div class="mb-4" [ngClass]="isApprove? 'col-lg-6 col-12':'col-lg-4 col-12'" *ngFor="let item of creative.creativeAssets; let i = index">
            <file-upload #fileUpload (openPreviewEvent)="openPreview($event)" (onAssetDelete)="removeAsset($event)" [reviewPage]='reviewPage' [isApprovedReported]='item.isValid!=null' [isApprove]='isApprove' filetypes="mp4" size=524288000 width=1080 height=1920 [campaign]="this.campaign" [creative]="this.creative" [asset]="i" type="video"></file-upload>
        </div>
    </div>

</div>