import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { MessageService, SelectItem } from 'primeng/api';
import { CampaignController } from '@Workspace/_generated/services';
import { Router } from '@angular/router';
import { ICreativeAssetDto, ICreativeDto } from '@Workspace/_generated/interfaces';
import { IBaseCampaignDto } from '@Workspace/_generated/interfaces';
import { Functions, Constants, LookUps } from '@Workspace/common';
import { AuthService } from '@Workspace/auth';
import { CartItemService } from 'apps/core/src/app/modules/shared-core/components/card-item/card-item.service';
import { takeUntil } from 'rxjs/operators';
import { eAccountType, eCTATikTok } from '@Workspace/_generated/enums';

@Component({
    selector: 'tiktok-in-feed-ads',
    templateUrl: './tiktok-in-feed-ads.component.html',
    styleUrls: ['./tiktok-in-feed-ads.component.scss']
})
export class TikTokInFeedAdsComponent implements OnInit, OnDestroy {
    constructor(
        public campaignController: CampaignController,
        public messageService: MessageService,
        public router: Router,
        private authService: AuthService,
        private cardItemService: CartItemService,
    ) { }

    @Input() creative: ICreativeDto;
    @Input() reviewPage: boolean = false;
    @Input() campaign: IBaseCampaignDto;
    @Input() isApprove: boolean = false;

    @Output() openPreviewEvent: EventEmitter<any> = new EventEmitter();

    isUploadOk: boolean = true;
    ngUnsubscribe = new Subject();
    constants = Constants;
    functions = Functions;
    enumAccountType = eAccountType;
    maxNumberOfFlights: number = 1;
    currentUser = this.authService.userSettings;
    
    callToActionLabel: string;
    callToActionDD: SelectItem[] = [];
    cta: number;

    ngOnInit() {
        this.maxNumberOfFlights = Math.max(...this.campaign.creatives.map(x => x.creativeGroup));

        this.cardItemService.data
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.isUploadOk = true;
                if (!!!data.creatives) return;
                this.creative.creativeAssets.forEach(ca => {
                    if (ca.isUploadValid != null || ca.isUploadValid != undefined)
                        this.isUploadOk = this.isUploadOk && ca.isUploadValid;
                });
            }
        );
        this.cta = Number(this.creative.detailJson?.callToAction);
        this.callToActionLabel = Functions.getEnumName(eCTATikTok, Number(this.creative.detailJson?.callToAction));
        this.callToActionDD = LookUps.getCTATikTokEnum();
        this.callToActionDD.find(x => x.value == eCTATikTok.Pre_Order_Now).label = 'Pre-Order Now';
        this.callToActionDD.unshift({ label: "-", value: 0 });
    }

    removeAsset(asset: ICreativeAssetDto) {
        if(this.functions.checkCanChangeAsset(this.creative.creativeGroup, this.creative.flightStartDate, this.campaign.startDate, this.campaign.campaignStatusId, this.campaign.statusId, this.campaign.campaignType, this.currentUser, this.creative.isBuilt, !!this.campaign.campaignInventoryItems ? this.campaign.campaignInventoryItems[0] : null, this.maxNumberOfFlights))
        {
            let index = this.creative.creativeAssets.indexOf(asset);
            this.creative.creativeAssets.splice(index, 1);
        }
    }

    openPreview(event) {
        this.openPreviewEvent.emit(event);
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    setCreativeCallToAction(event) {
        this.callToActionLabel = Functions.getEnumName(eCTATikTok, event.value);
        this.creative.detailJson.callToAction = this.cta.toString();
    }
}
