<div class="pl-3" class="left-border ml-5">
    <div class="my-3 row align-items-center ml-0 py-3" style=" border-width: 0.5px 0 0.5px 4px; border-style: solid; border-color: #e2e1e1 #e2e1e1 #e2e1e1 rgb(219, 71, 38);">
        <div class="col-lg-6 col-12 px-2 text-center d-flex align-items-center">
            <label class="my-0 font-weight-bold pl-3">{{"Flight " + creative.creativeGroup}}</label>
            <label class="my-0">{{" | " + (creative.creativeName ? creative.creativeName : creative.name)}}</label>
            <label class="my-0" *ngIf="functions.checkAreDatesVisible(creative.flightStartDate, creative.flightEndDate, campaign.startDate, campaign.endDate, creative.creativeGroup, campaign.creatives, campaign.campaignType).isVisible">{{functions.checkAreDatesVisible(creative.flightStartDate, creative.flightEndDate, campaign.startDate, campaign.endDate, creative.creativeGroup, campaign.creatives, campaign.campaignType).datesLabel}}</label>
        </div>

        <div class="col-lg-6 col-12 px-2 d-flex flex-wrap justify-content-lg-end justify-content-start buttons">
            <span tooltipPosition="top" [pTooltip]='video ? videoSizes : (entitlement ? entitlementSizes : (geoDemoTarget ? displaySizesGeoDemo : ( pageTakeover || rosDisplay ? displaySizesPageTakeover : (premiumPageTakeover ? displaySizesPremiumPageTakeover : (tradeDeal ? displaySizesTradeDeal : (shareOfVoice ? displaySizesShareOfVoice : defaultSizes))))))' tooltipStyleClass="tooltipStyle" class="fal fa-info-circle infoZip"></span>
            <!-- <i (click)="edit()" #creativeSwapsDialog class="fa fa-edit pr-1 edit" pTooltip='Edit flight'  ></i> -->
            <!-- <i *ngIf="creative.creativeGroup > 1 && functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.isLearfieldCampaign, campaign.isOASCampaign, campaign.isSocialCampaign, currentUser)" (click)="delete()" class="fa fa-trash-alt delete" pTooltip='Remove flight'></i> -->

        </div>
    </div>
    <div *ngIf="!isUploadOk && !reviewPage" class="mb-3 mt-3 row ml-0 invalid-message-box d-flex align-items-center" style="min-height: 3em;">
        <span class="pl-3">Incorrect file type or incorrect file dimensions or incorrect file size.</span>
    </div>
    <div *ngIf="checkIsFileUploaded()" class="mb-3 mt-3 row ml-0 invalid-message-box d-flex align-items-center" style="min-height: 3em;">
        <span class="pl-3">Missing creative image.</span>
    </div>
    <div *ngIf="!isLandingUrlOk && !landingPage" class="mb-3 mt-3 row ml-0 invalid-message-box d-flex align-items-center" style="min-height: 3em;">
        <span class="pl-3">{{!!validationLandingUrlMessageText?validationLandingUrlMessageText:'Missing Landing URL page for uploaded asset.'}}</span>
    </div>
    <div class="mb-3 d-flex flex-wrap">

        <div class="mb-4" [ngClass]="isApprove? 'col-lg-6 col-12':'col-lg-4 col-12'" *ngFor="let item of creative.creativeAssets; let i = index">
            <file-upload *ngIf="display" (openPreviewEvent)="openPreview($event)" (onAssetDelete)="removeAsset($event)" [reviewPage]='reviewPage' [isApprovedReported]='item.isValid!=null' [isApprove]='isApprove' filetypes="jpg, jpeg, png, gif" size="153600" [footer]='booleanTrue' [canDelete]="i+1 > defaultNumberOfAllowedSizes ? booleanTrue : booleanFalse" [campaign]="this.campaign" [creative]="this.creative" [asset]="i" [disableLandingPage]='!!landingPage' [landingPage]='landingPage'></file-upload>

            <file-upload *ngIf="video" (openPreviewEvent)="openPreview($event)" [reviewPage]='reviewPage' [isApprovedReported]='item.isValid!=null' [isApprove]='isApprove' filetypes="mp4" size="4294967296" [footer]='booleanTrue' [campaign]="this.campaign" [creative]="this.creative" type="video" [asset]="i"></file-upload>
        </div>

        <!--  && this.currentUser.permissions.isClient -->
        <div *ngIf="!reviewPage  && creative.creativeAssets.length < numberOfAllowedSizes && functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType , currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null)" class="col-4" style="cursor: pointer;">
            <div class="upload-wrapper border-ok text-center mt-2 d-flex flex-column align-items-center justify-content-center" style="height: 200px;" (click)="addCreativeAsset()">
                <i class="d-flex align-items-center justify-content-center fa fa-fw fa-plus"></i>
                <div>Add up to {{numberOfAllowedSizes - creative.creativeAssets.length}} more {{creative.creativeAssets.length == numberOfAllowedSizes - 1 ? 'asset' : 'assets'}}</div>
            </div>
        </div>

    </div>


</div>

<creative-swaps-dialog #creativeSwaps (onEditSave)="editCampaignDatesSaved($event)" (onRemoveSave)="removeCampaignDatesSaved($event)"></creative-swaps-dialog>