<div *ngIf="this.campaign.account.isHubStandardCampaign" class="mb-3">
    <span class="mb-3 ml-3 text-secondary"><strong>Stories</strong></span>
</div>
<div class="pl-3" [ngClass]="this.currentUser.permissions.isLearfieldClient ? 'left-border ml-5' : ''">
    <div *ngIf="!this.campaign.account.isHubStandardCampaign || (creative.name && this.campaign.account.isHubStandardCampaign)" class="my-3 row align-items-center ml-0 py-3" style=" border-width: 0.5px 0 0.5px 4px; border-style: solid; border-color: #e2e1e1 #e2e1e1 #e2e1e1 rgb(219, 71, 38);">
        <div class="col-lg-9 col-12 px-2 text-center d-flex align-items-center">
            <label class="my-0 font-weight-bold pl-3">{{this.creative.name}}</label>
            <label class="my-0" *ngIf="functions.checkAreDatesVisible(creative.flightStartDate, creative.flightEndDate, campaign.startDate, campaign.endDate, creative.creativeGroup, campaign.creatives, campaign.campaignType).isVisible">{{functions.checkAreDatesVisible(creative.flightStartDate, creative.flightEndDate, campaign.startDate, campaign.endDate, creative.creativeGroup, campaign.creatives, campaign.campaignType).datesLabel}}</label>
        </div>
    </div>

    <div *ngIf="!isUploadOk && !reviewPage" class="mb-3 mt-3 row ml-0 invalid-message-box d-flex align-items-center" style="min-height: 3em;">
        <span class="pl-3">Incorrect file type or incorrect file dimensions or incorrect file size.</span>
    </div>
    <div *ngIf="!isLandingUrlOk" class="mb-3 mt-3 row ml-0 invalid-message-box d-flex align-items-center" style="min-height: 3em;">
        <span class="pl-3">Missing Landing URL page for uploaded asset.</span>
    </div>
    <!-- <div class="mb-4 mt-3 pt-3">

        <div class="row">
            <div class="col-2">
                <label><strong>Destination URL</strong></label>
            </div>
            <div class="col-10 mr-0 pr-0">
                <input *ngIf="!reviewPage && this.currentUser.permissions.isClient" type="text" class="mr-0 pr-0" [(ngModel)]="creative.detailJson.destinationUrl" style="width: 100%; height: 3em;">
                <label *ngIf="reviewPage || !this.currentUser.permissions.isClient" class="mr-0 pr-0">{{creative.detailJson.destinationUrl}}</label>
            </div>
        </div>
    </div> -->
    <div class="mb-3" [ngClass]="isApprove? 'col-8':'col-4'">
        <p-dropdown placeholder="Select type" class="mr-0 pr-0" [disabled]="reviewPage" [options]="storiesTypes" [(ngModel)]="creative.detailJson.storiesType" (onChange)="onStoriesTypeSelected()"></p-dropdown>
    </div>
    <div class="mb-4" [ngClass]="isApprove? 'col-8':'col-4'" *ngIf="creative.detailJson.storiesType">
        <file-upload #fileUpload (openPreviewEvent)="openPreview($event)" [reviewPage]='reviewPage' [isApprovedReported]='creative.creativeAssets[0].isValid!=null' [isApprove]='isApprove' [footer]="true" 
                    filetypes="{{creative.detailJson.storiesType ==  enumStoriesType.Stories ? ' png, gif, jpg, jpeg' : creative.detailJson.storiesType ==  enumStoriesType.Reels ? ' mp4, mov': ''}}"
                    [size]="creative.detailJson.storiesType ==  enumStoriesType.Stories ? 1048576 : creative.detailJson.storiesType ==  enumStoriesType.Reels ? 10485760 : 1048576"
                    [storiesType]="creative.detailJson.storiesType"  [campaign]="this.campaign" [creative]="this.creative" [asset]="0" [landingPage]="landingPage"></file-upload>
    </div>

</div>