<div *ngIf="this.campaign.account.isHubStandardCampaign" class="mb-3">
    <span class="mb-3 ml-3 text-secondary"><strong>Instant Experience</strong></span>
</div>
<div class="pl-3" [ngClass]="this.currentUser.permissions.isLearfieldClient ? 'left-border ml-5' : ''">
    <div *ngIf="!this.campaign.account.isHubStandardCampaign || (creative.name && this.campaign.account.isHubStandardCampaign)" class="my-3 row align-items-center ml-0 py-3" style=" border-width: 0.5px 0 0.5px 4px; border-style: solid; border-color: #e2e1e1 #e2e1e1 #e2e1e1 rgb(219, 71, 38);">
        <div class="col-lg-9 col-12 px-2 text-center d-flex align-items-center">
            <label class="my-0 font-weight-bold pl-3">{{this.creative.name}}</label>
            <label class="my-0" *ngIf="functions.checkAreDatesVisible(creative.flightStartDate, creative.flightEndDate, campaign.startDate, campaign.endDate, creative.creativeGroup, campaign.creatives, campaign.campaignType).isVisible">{{functions.checkAreDatesVisible(creative.flightStartDate, creative.flightEndDate, campaign.startDate, campaign.endDate, creative.creativeGroup, campaign.creatives, campaign.campaignType).datesLabel}}</label>
        </div>
    </div>

    <div *ngIf="!isUploadOk && !reviewPage" class="mb-3 mt-3 row ml-0 invalid-message-box d-flex align-items-center" style="min-height: 3em;">
        <span class="pl-3">Incorrect file type or incorrect file dimensions or incorrect file size.</span>
    </div>

    <div class="mb-4 mt-3 pt-3">



        <div class="row align-items-center">
            <div class="col-lg-2 col-12">
                <label><strong>Destination Url</strong></label>
            </div>
            <div class="col-lg-10 col-12">
                <div class="row">
                    <div class="col-12">
                        <label *ngIf="reviewPage" class="mr-0 pr-0">{{creative.detailJson.destinationUrl}}</label>
                        <div class="input-group" *ngIf="!reviewPage">
                            <input type="text" class="form-control" [maxlength]=50 [disabled]="!!landingPage || !functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)" [(ngModel)]="!!landingPage ? landingPage : creative.detailJson.destinationUrl" [title]="isLandingUrlOk == false ? titleWrongUrl : !!landingPage ? landingPage : creative.detailJson.destinationUrl" style="overflow: hidden; text-overflow: ellipsis;" [ngClass]="this.isLandingUrlOk == false ? 'border-wrong' : ''" (focusout)="validationLandingPageMessage()">
                            <label class="input-group-append d-flex align-items-center justify-content-center px-2">{{50-creative.detailJson.destinationUrl.length}} CHARACTERS LEFT</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="mb-3 d-flex flex-wrap">
        <div class="mb-4" *ngFor="let item of creative.creativeAssets; index as i; first as isFirst" [ngClass]="isApprove? 'col-8':'col-4'">
            <file-upload [isHero]="isFirst" (openPreviewEvent)="openPreview($event)" (onAssetDelete)="removeAsset($event)" [canDelete]="dynamic && i> 3" [reviewPage]='reviewPage' [isApprovedReported]='creative.creativeAssets[i].isValid!=null' [isApprove]='isApprove' filetypes="jpg, jpeg, mp4" size=1073741824 [footer]='booleanFalse' [campaign]="this.campaign" [creative]="this.creative" [asset]="i"></file-upload>
        </div>
        <div *ngIf="!reviewPage && dynamic && this.currentUser.permissions.isClient && this.currentUser.userRole !=enumUserRole.LIMGC_AdOps_Admin && this.currentUser.userRole !=enumUserRole.LIMGCSocialAdOps && creative.creativeAssets.length<12 && functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)" class="col-4" style="cursor: pointer;">
            <div class="upload-wrapper border-ok text-center mt-2 d-flex flex-column align-items-center justify-content-center" style="height: 200px;" (click)="addCreativeAsset()">
                <i class="d-flex align-items-center justify-content-center fa fa-fw fa-plus"></i>
                <div>Add up to {{12 - creative.creativeAssets.length}} more {{creative.creativeAssets.length == 11 ? 'file' : 'files'}}</div>
            </div>
        </div>
    </div>
    <!-- <div *ngIf="creative.creativeAssets.length<12" class="p-2 bd-highlight"><button type="button" pButton (click)="addSlide()" class="flat-btn" label="+ Add slide"></button></div> -->

</div>