import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { MessageService, SelectItem } from 'primeng/api';
import { CampaignController } from '@Workspace/_generated/services';
import { eAccountType, eStoriesType } from '@Workspace/_generated/enums';
import { Router } from '@angular/router';
import { FileUpload } from '../../../../../shared-core/components/file-upload/file-upload.component';
import { CartItemService } from '../../../../../shared-core/components/card-item/card-item.service';
import { ICreativeAssetDto, ICreativeDto } from '@Workspace/_generated/interfaces';
import { IBaseCampaignDto } from '@Workspace/_generated/interfaces';
import { Constants, Functions, LookUps } from '@Workspace/common';
import { AuthService } from '@Workspace/auth';

@Component({
    selector: 'stories',
    templateUrl: './stories.component.html',
    styleUrls: ['./stories.component.scss']
})
export class StoriesComponent implements OnInit, OnDestroy {
    constructor(
        public campaignController: CampaignController,
        public messageService: MessageService,
        public router: Router,
        private cardItemService: CartItemService,
        private authService: AuthService,
    ) { }

    @Input() creative: ICreativeDto;
    @Input() reviewPage: boolean = false;
    @Input() isApprove: boolean = false;
    @Input() isApprovedReported: boolean;
    @Input() campaign: IBaseCampaignDto;
    @Input() landingPage: string;
    @Output() openPreviewEvent: EventEmitter<any> = new EventEmitter();
    isPreviewOpen: boolean;
    currentIndex: number;
    creativeAssetsForPreview: ICreativeAssetDto[] = [];
    ngUnsubscribe = new Subject();
    booleanTrue: boolean = true;
    booleanFalse: boolean = false;
    isUploadOk: boolean = true;
    isLandingUrlOk: boolean = true;
    constants = Constants;
    functions = Functions;
    enumAccountType = eAccountType;

    enumStoriesType = eStoriesType;
    storiesTypes: SelectItem[] = [];

    @ViewChild('fileUpload') fileUpload: FileUpload;

    currentUser = this.authService.userSettings;

    ngOnInit() {
        this.storiesTypes = LookUps.getStoriesEnum();

        this.creative.creativeAssets.forEach(x => {
            if (!!x.file && !!x.file.urlWithToken)
                this.creativeAssetsForPreview.push(x);
        });
        this.cardItemService.data
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.creative = data.creatives.find(x => x.id === this.creative.id && x.creativeTypeId === this.creative.creativeTypeId);
                this.isUploadOk = true;
                this.isLandingUrlOk = true;
                if (!!!data.creatives) {
                    return;
                }
                this.creative.creativeAssets.forEach(ca => {
                    if (ca.isUploadValid != null || ca.isUploadValid != undefined)
                        this.isUploadOk = this.isUploadOk && ca.isUploadValid;
                    if (ca.isLandingPageExist != null || ca.isLandingPageExist != undefined)
                        this.isLandingUrlOk = this.isLandingUrlOk && ca.isLandingPageExist;
                });
            });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }


    openPreview(event) {
        this.openPreviewEvent.emit(event);
        // this.currentIndex = this.creativeAssetsForPreview.findIndex(x => x.id == event.id);
        // this.isPreviewOpen = true;
    }

    onStoriesTypeSelected(){
        setTimeout(() => {
            if (this.fileUpload) {
                this.fileUpload.changeFileType();
                this.fileUpload.deleteUpload();
                this.cardItemService.updatedDataSelection(this.campaign);
                this.fileUpload.url = null;
                this.fileUpload.isPicture = false;
                this.fileUpload.isVideo = false;
                this.fileUpload.isAudio = false;
                this.fileUpload.originalWidth = 0;
                this.fileUpload.originalHeight = 0;
            }
        }, 0);

    }

    showHideDialog(event: any) {
        this.isPreviewOpen = event.visible;
    }

}
