import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { eAccountType, eCallToAction, eCTALinkedinImage, eCTALinkedinVideo } from '@Workspace/_generated/enums';
import { IBaseCampaignDto, ICreativeAssetDto, ICreativeDto } from '@Workspace/_generated/interfaces';
import { AuthService } from '@Workspace/auth';
import { Constants, Functions, LookUps } from '@Workspace/common';
import { CartItemService } from 'apps/core/src/app/modules/shared-core/components/card-item/card-item.service';
import { SelectItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'linkedin-news-feed',
    templateUrl: './linkedin-news-feed.component.html',
    styleUrls: ['./linkedin-news-feed.component.scss']
})
export class LinkedinNewsFeedComponent implements OnInit, OnDestroy {
    constructor(
        private cardItemService: CartItemService,
        private authService: AuthService,
    ) { }

    @Input() reviewPage: boolean = false;
    @Input() creative: ICreativeDto;
    @Input() campaign: IBaseCampaignDto;
    @Input() isApprove: boolean = false;
    @Input() isLinkedinImage: boolean = false;

    @Output() uploadValidationMessage: EventEmitter<any> = new EventEmitter();
    @Output() openPreviewEvent: EventEmitter<any> = new EventEmitter();

    ngUnsubscribe = new Subject();
    functions = Functions;
    constants = Constants;
    currentUser = this.authService.userSettings;
    enumAccountType = eAccountType;

    isUploadOk: boolean = true;
    maxNumberOfFlights: number = 1;
    isDestinationUrlOk: boolean = true;
    callToActionLabel: string;
    callToActionDD: SelectItem[] = [];
    cta: number;

    ngOnInit() {
        this.maxNumberOfFlights = Math.max(...this.campaign.creatives.map(x => x.creativeGroup));

        this.cardItemService.data
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.isUploadOk = true;
                if (!!!data.creatives) return;
                this.creative.creativeAssets.forEach(ca => {
                    if (ca.isUploadValid != null || ca.isUploadValid != undefined)
                        this.isUploadOk = this.isUploadOk && ca.isUploadValid;
                });
            }
        );
        this.cta = Number(this.creative.detailJson?.callToAction);
        this.callToActionLabel = this.isLinkedinImage ? Functions.getEnumName(eCTALinkedinImage, Number(this.creative.detailJson?.callToAction)) : Functions.getEnumName(eCTALinkedinVideo, Number(this.creative.detailJson?.callToAction));
        this.callToActionDD = this.isLinkedinImage ? LookUps.getCTALinkedinImageEnum() : LookUps.getCTALinkedinVideoEnum();
        if(this.isLinkedinImage)
            this.callToActionDD.find(x => x.value == eCTALinkedinImage.Register_Sign_Up_Or_Subscribe).label = 'Register, Sign up, or Subscribe';
        this.callToActionDD.unshift({ label: "-", value: 0 });
    }

    removeAsset(asset: ICreativeAssetDto) {
        if(this.functions.checkCanChangeAsset(this.creative.creativeGroup, this.creative.flightStartDate, this.campaign.startDate, this.campaign.campaignStatusId, this.campaign.statusId, this.campaign.campaignType, this.currentUser, this.creative.isBuilt, !!this.campaign.campaignInventoryItems ? this.campaign.campaignInventoryItems[0] : null, this.maxNumberOfFlights))
        {
            let index = this.creative.creativeAssets.indexOf(asset);
            this.creative.creativeAssets.splice(index, 1);
        }
    }

    openPreview(event) {
        this.openPreviewEvent.emit(event);
    }

    setCreativeCallToAction(event) {
        this.callToActionLabel = Functions.getEnumName(eCallToAction, event.value);
        this.creative.detailJson.callToAction = this.cta.toString();
    }

    validationDestinationUrlMessage() {
        let textToMacth = this.creative.detailJson.destinationUrl;
        // var regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?%#[\]@!\$&'\(\)\*\+,;=.]+$/;
        var regex = /^[a-zA-Z0-9]+[^.\n]*[.][^.\n]+([.][^.\n]+)*$/;
        var found = textToMacth.match(regex);
        this.isDestinationUrlOk = true;
        if (found == null) {
            this.isDestinationUrlOk = false;
        }
    }

    uploadWarning(message: string){
        this.uploadValidationMessage.emit(message);
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
