<div class="mb-3">
    <span class="mb-3 ml-3 text-secondary"><strong>Connected TV</strong></span>
</div>
<div class="pl-3" [ngClass]="this.currentUser.permissions.isLearfieldClient ? 'left-border ml-5' : ''">
    <div *ngIf="!this.campaign.account.isHubStandardCampaign || (creative.name && this.campaign.account.isHubStandardCampaign)" class="my-3 row align-items-center ml-0 py-3" style=" border-width: 0.5px 0 0.5px 4px; border-style: solid; border-color: #e2e1e1 #e2e1e1 #e2e1e1 rgb(219, 71, 38);">
        <div class="col-lg-9 col-12 px-2 text-center d-flex align-items-center">
            <label class="my-0 font-weight-bold pl-3">{{"Flight " + creative.creativeGroup}}</label>
            <label class="my-0">{{" | " + (creative.creativeName ? creative.creativeName : creative.name)}}</label>
            <label class="my-0" *ngIf="functions.checkAreDatesVisible(creative.flightStartDate, creative.flightEndDate, campaign.startDate, campaign.endDate, creative.creativeGroup, campaign.creatives, campaign.campaignType).isVisible">{{functions.checkAreDatesVisible(creative.flightStartDate, creative.flightEndDate, campaign.startDate, campaign.endDate, creative.creativeGroup, campaign.creatives, campaign.campaignType).datesLabel}}</label>
            <span tooltipPosition="top" [pTooltip]='constants.connectedTvInfo' class="fal fa-info-circle info"></span>
        </div>
        <div *ngIf="this.campaign.account.isLearfieldCampaign" class="col-lg-6 col-12 px-2 d-flex flex-wrap justify-content-lg-end justify-content-start">
        </div>
    </div>


    <div *ngIf="!isUploadOk && !reviewPage" class="mb-3 mt-3 row ml-0 invalid-message-box d-flex align-items-center" style="min-height: 3em;">
        <span class="pl-3">Incorrect file type or incorrect file dimensions or incorrect file size.</span>
    </div>
    <div class="mb-4 mt-3 pt-3">


        <div class="row align-items-center mb-2" *ngIf="!campaign.account.isLearfieldCampaign">
            <div class="col-lg-2 col-12">
                <label><strong>Video Type</strong></label>
            </div>
            <div class="col-lg-10 col-12">
                <p-dropdown autoWidth="false" [disabled]="reviewPage || currentUser.permissions.isClient" [style]="{'width': '100%'}" [(ngModel)]="creative.detailJson.videoType" [options]="videoTypes"></p-dropdown>
            </div>
        </div>

        <div class="row align-items-center mb-2">
            <div class="col-lg-2 col-12">
                <label><strong>Landing Page URL</strong></label>
            </div>
            <div class="col-lg-10 col-12">
                <input class="form-control" *ngIf="!reviewPage " [disabled]="!!landingPage || !functions.checkCanChangeAsset(creative.creativeGroup, creative.flightStartDate, campaign.startDate, campaign.campaignStatusId, campaign.statusId, campaign.campaignType, currentUser, creative.isBuilt, !!campaign.campaignInventoryItems ? campaign.campaignInventoryItems[0] : null, maxNumberOfFlights)" type="text" [(ngModel)]="!!landingPage?landingPage:creative.detailJson.landingPageUrl" [title]="isLandingUrlOk == false ? titleWrongUrl : !!landingPage?landingPage:creative.detailJson.landingPageUrl" style="overflow: hidden; text-overflow: ellipsis;" [ngClass]="this.isLandingUrlOk == false ? 'border-wrong' : ''" (focusout)="validationLandingPageMessage()">
                <span *ngIf="!reviewPage" tooltipPosition="top" [pTooltip]='constants.landingPageInfo' tooltipStyleClass="tooltipStyle" class="fal fa-info-circle info2"></span>
                <label *ngIf="reviewPage" class="mr-0 pr-0">{{creative.detailJson.landingPageUrl}}</label>
            </div>
        </div>

    </div>

    <div class="mb-4" [ngClass]="isApprove? 'col-8':'col-4'">
        <file-upload (openPreviewEvent)="openPreview($event)" [reviewPage]='reviewPage' [isApprovedReported]='creative.creativeAssets[0].isValid!=null' [isApprove]='isApprove' filetypes="mp4" size=1073741824 [footer]='booleanFalse' [campaign]="this.campaign" [creative]="this.creative" [asset]="0"></file-upload>
    </div>
</div>

<creative-swaps-dialog #creativeSwaps (onEditSave)="editCampaignDatesSaved($event)" (onRemoveSave)="removeCampaignDatesSaved($event)"></creative-swaps-dialog>